<template>
  <a-drawer width="70%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <div class="order-info">
      <table>
        <tr>
          <td style="vertical-align: top;background-color:#fbfbfb;">
            <a-card  :bordered="false" title="订单信息"
                     :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                     :bodyStyle="{'background-color':'#fbfbfb'}">
              <table class="info-table">
                <tr>
                  <td class="first">订单编号：</td>
                  <td class="data">
                    {{ this.record.id  }}
                  </td>
                  <td class="first">订单类型：</td>
                  <td class="data">
                    {{ this.record.orderTypeName  }}
                  </td>
                  <td class="first">订单状态：</td>
                  <td class="data">
                    {{this.record.orderStatusName  }}
                  </td>
                </tr>
                <tr>
                  <td class="first">用户名：</td>
                  <td class="data">
                    {{ this.record.nickname  }}
                  </td>
                  <td class="first">用户手机号：</td>
                  <td class="data">
                    {{ this.record.mobile  }}
                  </td>
                  <td class="first">审核状态：</td>
                  <td class="data">
                    {{this.record.auditStatusName  }}
                  </td>
                </tr>
                <tr>
                  <td class="first">下单时间：</td>
                  <td class="data">
                    {{ this.record.createTime  }}
                  </td>
                  <td class="first">支付时间：</td>
                  <td class="data">
                    {{ this.record.payTime  }}
                  </td>
                  <td class="first">支付方式：</td>
                  <td class="data">
                    {{ this.record.payTypeName  }}
                  </td>
                </tr>
                <tr>
                  <td class="first">订单金额：</td>
                  <td class="data">
                    {{ this.record.payAmount  }}
                  </td>
                  <td class="first">微信订单号：</td>
                  <td class="data">
                    {{ this.record.outTradeNo  }}
                  </td>
                  <td class="first"></td>
                  <td class="data">
                  </td>
                </tr>
              </table>
            </a-card>
          </td>
        </tr>
        <tr>
          <td style="vertical-align: top;background-color:#fbfbfb;">
            <a-card  :bordered="false" title="会议室信息"
                     :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                     :bodyStyle="{'background-color':'#fbfbfb'}">
              <table class="info-table">
                <tr>
                  <td class="first">会议室名称：</td>
                  <td class="data">
                    {{ this.record.orderSiteSnapshot.siteName  }}
                  </td>
                  <td class="first">开放时间：</td>
                  <td class="data">
                    {{ this.record.orderSiteSnapshot.openTime  }}
                  </td>
                  <td class="first">单价：</td>
                  <td class="data">
                    {{ this.record.price  }}
                  </td>
                </tr>
                <tr>
                  <td class="first">场次：</td>
                  <td class="data">
                    {{ this.record.siteTimeName  }}
                  </td>
                  <td class="first">总天数：</td>
                  <td class="data">
                    {{ this.record.totalDay  }}
                  </td>
                  <td class="first">已选日期：</td>
                  <td class="data">
                    <li v-for="item in this.record.siteTimeGroupList">
                      {{ item }}
                    </li>
                  </td>
                </tr>
                <tr>
                  <td class="first">预约开始时间：</td>
                  <td class="data">
                    {{ this.record.startTime  }}
                  </td>
                  <td class="first">总价：</td>
                  <td class="data">
                    {{ this.record.orderAmount  }}
                  </td>
                </tr>
              </table>
            </a-card>
          </td>
        </tr>
        <tr>
          <td style="vertical-align: top;background-color:#fbfbfb;">
            <a-card  :bordered="false" title="下单信息"
                     :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                     :bodyStyle="{'background-color':'#fbfbfb'}">
              <table class="info-table">
                <tr>
                  <td class="first">公司名称：</td>
                  <td class="data">
                    {{ this.record.subjectName  }}
                  </td>
                  <td class="first">联系电话：</td>
                  <td class="data">
                    {{ this.record.contactPhone  }}
                  </td>
                  <td class="first">预计人数：</td>
                  <td class="data">
                    {{ this.record.personNum  }}
                  </td>
                </tr>
                <tr>
                  <td class="first">活动主题：</td>
                  <td class="data">
                    {{ this.record.theme  }}
                  </td>
                  <td class="first">其他需求：</td>
                  <td class="data">
                    {{ this.record.remark  }}
                  </td>
                </tr>
              </table>
            </a-card>
          </td>
        </tr>
      </table>
    </div>
    <div>
      <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
        <a-form-model-item label="超时日期 " prop="orderDay" >
          <a-date-picker style="width: 100%" v-model="form.orderDay"
                         :disabled-date="disabledDate"
                         @change="orderDayChange"  format="YYYY-MM-DD"  allow-clear/>
        </a-form-model-item>
        <a-form-model-item label="超时时间(分钟) " prop="timeOutMin" >
          <a-input-number v-model="form.timeOutMin" style="width: 100%"   :min="0" :max="99999"  placeholder="请输入超时时间" />
        </a-form-model-item>
        <a-form-model-item label="超时总额" prop="amount" >
          <a-input v-model="form.amount" style="width: 100%"  :maxLength="9" @change="limitAmount(form.amount)" placeholder="请输入超时总额"  allow-clear />
        </a-form-model-item>
        <a-form-model-item label="备注信息" prop="remark" >
          <a-input v-model="form.adminRemark" placeholder="请输入备注" type="textarea"
                   allow-clear />
        </a-form-model-item>
        <div class="bottom-control">
          <a-space>
            <a-button type="primary" :loading="submitLoading" @click="submitForm">
              确认生成
            </a-button>
            <a-button type="dashed" @click="cancel">
              取消
            </a-button>
          </a-space>
        </div>
      </a-form-model>
    </div>
  </a-drawer>
</template>

<script>
import moment from 'moment';
import {createTimeOut, getSiteOrderDetail} from '@/api/order/site'
import {getSite} from '@/api/res/site'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'TimeoutForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      // 表单参数
      record:{},
      form: {
        id: null,
        timeOutMin: null,
        orderDay:null,
        price:null,
        amount:null,
        adminRemark: null,
      },
      //场地详情,名称,套餐,时间
      siteForm: {
        id: null,
        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        orderDay: [
          { required: true, message: '超时日期不能为空', trigger: 'change' }
        ],
        amount: [
          { required: true, message: '超时金额不能为空', trigger: 'change' }
        ],
        timeOutMin: [
          { required: true, message: '超时时间不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    moment,
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        timeSlot: {},
        orderDay:null,
        price:null,
        amount:null,
        remark: null,
      }
    },
    orderDayChange(date, dateString){
      this.form.orderDay = dateString
    },
    startTimePeriodChange(date, dateString){
      console.log("da",dateString)
      this.form.startTime = dateString
    },
    endTimePeriodChange(date, dateString) {
      this.form.endTime = dateString
    },
    limitAmount(value) {
      // console.log(i);
      let val=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1');
      if(val==null||val==undefined||val==''){
        val=0
      }
      this.form.amount=val
    },
    limitPriceAmount(record) {
      // console.log(i);
      let val=record.price.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1');
      if(val==null||val==undefined||val==''){
        val=0
      }
      this.form.price = val
    },
    disabledDate(current) {
      return !this.record.siteTimeList.includes(current.format("YYYY-MM-DD"))
    },
    /** 创建超时订单操作 */
    handleCreateTimeOut (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSite({"id":row.siteId}).then(response => {
        this.siteForm = response.data
      })
      getSiteOrderDetail({"id":id}).then(response => {
        this.form = response.data
        this.form.orderDay = null
        this.record = response.data
        this.open = true
        this.formTitle = '生成超时订单'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            createTimeOut(this.form).then(response => {
              this.$message.success(
                '成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, .85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.message-text {
  font-family: MicrosoftYaHei;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 3rem;
  letter-spacing: 0rem;
  color: #333333;
  width: 50%;
}

.fontblack {
  color: #999999
}

.img2 {
  width: .81rem;
  height: .8rem;
  float: right;
}


.orderTitle {
  font-size: 1rem;
  color: #333333;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  height: 2.5rem;
}

.orderDetail {
  font-size: 0.8rem;
  color: #666666;
  text-align: left;
}

.border-ceter {
  width: 92%;
  padding-left: 15px;
  padding-right: 15px;
}

.pay-button {
  width: 88%;
  height: 2.6rem;
  position: relative;
  background-color: red;
  color: white;

  margin-left: 6%;
}

ul li {
  list-style: none;
  font-size: 1rem;
}

ul {
  padding-left: 1.5rem
}

.track-rcol {
}

.track-list {
  position: relative;
}

.track-list li {
  position: relative;
  padding: 0px 0 1.5rem 25px;
  line-height: 1rem;
  border-left: 1px solid #d9d9d9;
  color: #999;
}

.track-list li.first {
  color: red;
  padding-top: 0;
  width: 100%;
  text-align: left;
  border-left: 1px solid #d9d9d9;
}

.track-list li .node-icon {
  position: absolute;
  left: -5.5px;
  border-radius: 0.25rem;
  width: 0.5rem;
  height: 0.5rem;
  top: 4px;
  background-color: #999999;
}

.track-list li.first .node-icon {
  background-position: 0-72px;
  background-color: red;
  width: 1rem;
  z-index: 2;
  height: 1rem;
  position: absolute;
  left: -9px;
  top: 0;
  border-radius: 0.5rem;
}

.track-list li .time {
  margin-right: 20px;
  position: relative;
  top: 4px;
  display: inline-block;
  vertical-align: middle;
  background-color: white;
  color: #999;
  width: 100%;
  text-align: left;
}

.track-list li .txt {
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: #999;
}

.track-list li.first .time {
  text-align: left;
  width: 94%;
  color: red;
}

.track-list li.first .txt {
  color: red;
  text-align: left;
  width: 94%;
}

.track-list li.finall {
  position: relative;
  padding: 0px 0 1.5rem 25px;
  line-height: 18px;
  border-color: white;
  border-left: 1px solid #ffffff;
  color: #999;
}

.track-list li.finall .div-spilander {
  width: 1px;
  position: absolute;
  position: absolute;
  left: -1.5px;
  height: 0.5rem;
  background-color: #d9d9d9;
}

.order-info {
  border: 1px solid #e8e8e8;
  margin-top: 40px;

  .info-table {
    tr {
      height: 30px;
    }
    td {
      display: table-cell;
      vertical-align: top;
      font-size: 16px;
    }

    .first {
      width: 100px;
    }

    .data {
      width: 300px;
    }
  }
}

.detailPanel {
  dd, dt {
    display: inline-block;
    vertical-align: center;
  }

  dt img {
    margin: 0 20px 5px 0px;
  }

  dd {
    h3 {
      font-weight: bold;
    }
  }

  .trade-detail-prompt {
    margin-left: 35px;

    li {
      font-size: 13px !important;

      .dotted-node {
        padding: 0 5px;
      }
    }
  }
}

.order-money {
  .om-c1 {
    font-size: 14px;
    color: #666
  }

  .om-c2 {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  .om-c3 {
    font-size: 16px;
    color: #DE3636;
    font-weight: bold;
  }

  td {
    text-align: right;
  }

  float: right;
  margin: 20px 40px 20px 0;
}
</style>