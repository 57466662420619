import request from '@/utils/request'


// 查询res-场地列表
export function listSite(query) {
  return request({
    url: '/res/site/list',
    method: 'get',
    params: query
  })
}

// 查询res-场地分页
export function pageSite(query) {
  return request({
    url: '/res/site/page',
    method: 'get',
    params: query
  })
}

// 查询res-场地详细
export function getSite(data) {
  return request({
    url: '/res/site/detail',
    method: 'get',
    params: data
  })
}

// 查询res-场地库存
export function getStock(data) {
  return request({
    url: '/res/site/stock',
    method: 'post',
    data: data
  })
}

// 新增res-场地
export function addSite(data) {
  return request({
    url: '/res/site/add',
    method: 'post',
    data: data
  })
}

// 修改res-场地
export function updateSite(data) {
  return request({
    url: '/res/site/edit',
    method: 'post',
    data: data
  })
}

// 删除res-场地
export function delSite(data) {
  return request({
    url: '/res/site/delete',
    method: 'post',
    data: data
  })
}
