import request from '@/utils/request'


// 查询订单-场地订单列表
export function listSite(query) {
  return request({
    url: '/order/site/list',
    method: 'get',
    params: query
  })
}

// 查询订单-场地订单分页
export function pageSite(query) {
  return request({
    url: '/order/site/page',
    method: 'get',
    params: query
  })
}

// 查询订单-场地订单详细
export function getSiteOrderDetail(data) {
  return request({
    url: '/order/site/detail',
    method: 'get',
    params: data
  })
}

// 新增订单-场地订单
export function addSite(data) {
  return request({
    url: '/order/site/add',
    method: 'post',
    data: data
  })
}

export function refundSite(data) {
  return request({
    url: '/order/site/refund',
    method: 'post',
    data: data
  })
}

// 修改订单-场地订单
export function updateSite(data) {
  return request({
    url: '/order/site/edit',
    method: 'post',
    data: data
  })
}

// 修改订单-场地订单
export function auditSite(data) {
  return request({
    url: '/order/site/audit',
    method: 'post',
    data: data
  })
}
export function paySite(data) {
  return request({
    url: '/order/site/pay',
    method: 'post',
    data: data
  })
}
export function createTimeOut(data) {
  return request({
    url: '/order/site/timeout',
    method: 'post',
    data: data
  })
}

// 删除订单-场地订单
export function delSite(data) {
  return request({
    url: '/order/site/delete',
    method: 'post',
    data: data
  })
}


// 取消订单-场地订单
export function cancelSite(data) {
  return request({
    url: '/order/site/cancel',
    method: 'post',
    data: data
  })
}